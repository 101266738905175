import { connect } from 'react-redux'

import { nextStep, submitZip, toggleApply, toggleField } from '../../../reducers/captive'

import Zip from './zip'

export default connect((state) => state, {
  nextStep,
  submitZip,
  toggleApply,
  toggleField,
})(Zip)
