import React from 'react'

const ShopPhone = ({ phone }) => (
  <div className="bg-white base-section t-center tight">
    <div className="container w-60 w-80-s">
      <h2>Just looking to shop over the phone?</h2>
      <p>
        No problem! Call now to speak with a licensed agent and get expert advice on
        choosing a plan that's just right for you.
      </p>
      <a href={`tel:${phone}`} className="btn with-icon btn-large">
        <span className="icon-phone" /> <span className="text">{phone}</span>
      </a>
      <div>9:00am - 7:00pm ET</div>
    </div>
  </div>
)

export default ShopPhone
