import React, { Component } from 'react'
import { navigate } from 'gatsby'
import { globalHistory } from '@reach/router'
import PropTypes from 'prop-types'

class Zip extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '', disabled: 'disabled' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.homeOrigin) {
      this.props.toggleField('homeOrigin', this.props.homeOrigin)
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.captive.data.zip !== this.props.captive.data.zip) {
      const zip = this.props.captive.data.zip
      this.setState({ value: zip, disabled: zip.length !== 5 })
    }
  }
  handleSubmit = (e = null) => {
    if (e) {
      e.preventDefault()
    }
    this.props.submitZip(this.state.value)
    this.props.nextStep('zip')

    if (this.props.toggleApplyLayout) {
      this.props.toggleApply(true)
    }

    if (this.props.firePopUnder) {
      if (window.open) {
        let params = {
          a: 'b',
          zip: this.state.value,
          ...(this.props.includeCitySearch && { includeCitySearch: true }),
          ...this.props.popoverCarryOverData,
        }
        if (this.props.captive.src !== '') {
          params['SRC'] = this.props.captive.src
        }
        if (!this.props.captive.otherInsuranceDefault) {
          params['other'] = 'false'
        }
        const esc = encodeURIComponent
        let query = Object.keys(params)
          .map((k) => esc(k) + '=' + esc(params[k]))
          .join('&')

        window.open(globalHistory.location.origin + '/apply/birth/?' + query, '_blank')

        navigate('/offers-m/?zip=' + this.state.value)
      } else {
        navigate('/apply/birth')
      }
    } else {
      navigate('/apply/birth')
    }
  }
  handleChange(event) {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 5 ? '' : 'disabled'
    this.setState({ value: event.target.value, disabled: isDisabled }, () => {
      if (event.target.value.length > 4) {
        this.handleSubmit()
      }
    })
  }

  render = () => (
    <div>
      <strong className="h4">Please enter your zip code</strong>
      <form
        action="#"
        onSubmit={this.handleSubmit}
        className="form-validation"
        noValidate="novalidate"
      >
        <div className="row">
          <div className="col full-width">
            <label htmlFor="zip" className="hidden">
              Zip
            </label>
            <input
              className="data-hj-whitelist"
              maxLength="5"
              type="text"
              id="zip"
              data-required="true"
              value={this.state.value}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="btn-wrap text-center">
          <button
            type="submit"
            disabled={this.state.disabled}
            className={`btn` + (this.state.disabled ? ` disabled` : ``)}
          >
            {this.props.btnText}
          </button>
        </div>
        {!this.props.hideSecList && (
          <ul className="security-list">
            <li>
              <img src="/images/Norton-Logo.png" alt="description of thing" />
            </li>
            <li>
              <img src="/images/McAfee-Logo.png" alt="description of thing" />
            </li>
            <li>
              <img src="/images/BBB-Logo.png" alt="description of thing" />
            </li>
          </ul>
        )}
      </form>
    </div>
  )
}

Zip.propTypes = {
  shouldNavigate: PropTypes.bool,
  hideSecList: PropTypes.bool,
  btnText: PropTypes.string,
  firePopUnder: PropTypes.bool,
  toggleApplyLayout: PropTypes.bool,
  popoverCarryOverData: PropTypes.object,
  homeOrigin: PropTypes.string,
}

Zip.defaultProps = {
  shouldNavigate: false,
  btnText: 'Continue',
  firePopUnder: false,
  toggleApplyLayout: false,
  popoverCarryOverData: {},
  hideSecList: false,
  homeOrigin: null,
}

export default Zip
